<template>
  <div
    class="step-holder"
    v-bind:class="{ completed: activeJobEditStep > 4, active: activeJobEditStep === 4 }"
  >
    <div class="title-bar">
      <div style="width: 100%">
        <div style="display: flex; justify-content: space-between">
          <h3><span>4. Lenses</span></h3>
          <div>
            <a @click="editThisStep" v-if="activeJobEditStep > 4">Change</a
            ><a class="ml-4" @click="resetThisStep" v-if="activeJobEditStep > 4">Reset</a>
          </div>
        </div>
        <div class="selected-features" v-if="activeJobEditStep > 4 && selectedLens">
          <template v-if="selectedLens.name">
            <div><b>Supplier</b>{{ getLensSupplierName(selectedLens.supplier_id) }}</div>
            <div><b>Lens</b>{{ selectedLens.name }}</div>
            <div><b>Color</b>{{ lensColor }}</div>
            <div>
              <b>Coating</b
              >{{ selectedLens.coating + (lensCoating ? ' - ' : '') + (lensCoating || '') }}
            </div> </template
          ><template v-else>
            <div><b>Supplier</b>{{ selectedLens.lenses.supplier.name }}</div>
            <div><b>Lens</b>{{ selectedLens.lenses.name }}</div>
            <div><b>Color</b>{{ lensColor }}</div>
            <div>
              <b>Coating</b
              >{{ selectedLens.lenses.coating + (lensCoating ? ' - ' : '') + (lensCoating || '') }}
            </div>
          </template>
        </div>
      </div>
    </div>
    <ValidationObserver v-slot="{ handleSubmit, invalid }">
      <b-form @submit.stop.prevent="handleSubmit(sendLensForm)" v-if="activeJobEditStep === 4">
        <ValidationProvider name="Lenses" rules="required" v-slot="validationContext">
          <LensList
            :customer="customer"
            :prescription="prescription"
            :showTitle="true"
            :selectable="true"
            :collapsible="true"
            v-model="$store.state.jobsModule.selectedLens"
            :state="getValidationState(validationContext)"
          ></LensList>
          <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
        </ValidationProvider>
        <div class="confirmation-wrapper">
          <b-button
            class="mr-3"
            variant="outline-secondary"
            @click="setActiveJobEditStep(5)"
            type="button"
            >Cancel</b-button
          >
          <b-button class="button-with-loader" type="submit" :disabled="invalid" variant="primary"
            >Confirm</b-button
          >
        </div>
      </b-form>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import LensList from '../../LensList.vue';

export default {
  name: 'Lenses',
  props: {
    customer: Object,
    prescription: Object,
    orderLens: Object,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      activeJobEditStep: state => state.jobsModule.activeJobEditStep,
      selectedLens: state => state.jobsModule.selectedLens,
      selectedLensCoating: state => state.jobsModule.selectedLensCoating,
    }),
    lensCoating() {
      if (this.selectedLensCoating && this.selectedLens && this.selectedLens.lenses) {
        const seletedCoating = this._.find(this.selectedLensCoating, {
          lens_id: this.selectedLens.lenses.id,
        });
        return (
          this._.get(seletedCoating, 'coating.display_name') ||
          this._.get(seletedCoating, 'coating.name')
        );
      }
      return (
        this._.get(
          this.orderLens,
          'additional_info.data.lens_addon_combination.coating.display_name',
        ) || this._.get(this.orderLens, 'additional_info.data.lens_addon_combination.coating.name')
      );
    },
    lensColor() {
      const getColor = this._.get(this.selectedLens, 'lens_color.name');
      if (getColor) {
        return getColor;
      }
      return this._.get(this.selectedLens, 'lenses.lens_color.name');
    },
  },
  components: {
    LensList,
    ValidationProvider,
    ValidationObserver,
  },
  methods: {
    editThisStep() {
      this.$store.commit('jobsModule/SET_ACTIVE_JOB_EDIT_STEP', 4);
    },
    resetThisStep() {
      this.$store.commit('jobsModule/SET_SELECTED_LENS', null);
      this.$store.commit('jobsModule/SET_ACTIVE_JOB_EDIT_STEP', 4);
      if (this.selectedLensCoating) {
        this.$store.commit('jobsModule/SET_SELECTED_LENS_COATING', null);
      }
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    },
    sendLensForm() {
      this.$emit('lensDataSent', this.selectedLens);
      this.$store.commit('jobsModule/SET_ACTIVE_JOB_EDIT_STEP', 5);
    },
  },
};
</script>

<style scoped></style>
