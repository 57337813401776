<template>
  <b-modal id="order-lens-modal" size="xl" hide-footer="" @show="onShow" @hide="onHide"
    ><template v-slot:modal-header="{ close }">
      <b-container>
        <div class="modal-title">
          <h3 class="text-bold text-center">Order Lenses</h3>
          <h6 class="text-uppercase text-center">{{ modalTitle }}</h6>
          <button class="close" type="button" aria-label="Close" @click="close">
            <i class="di-remove-10"></i>
          </button>
        </div>
      </b-container>
    </template>
    <div class="container" v-if="orderLensData">
      <ValidationObserver v-slot="{ handleSubmit }">
        <b-form
          class="mb-5"
          ref="orderLensForm"
          @submit.stop.prevent="handleSubmit(sendOrder)"
          novalidate="novalidate"
        >
          <div class="repeat-reason-box" v-if="orderLensData.count > 0">
            <div class="title">
              <h6>Repeat Order</h6>
              <span>This will be a repeat order. Please choose a reason for the order</span>
            </div>
            <b-row class="small-gutter">
              <b-col sm="4">
                <ValidationProvider name="reason" rules="required" v-slot="validationContext">
                  <b-form-group label="Reason" label-for="reason">
                    <b-form-select
                      id="delivery"
                      name="reason"
                      v-model="form.reason"
                      :state="getValidationState(validationContext)"
                      :options="reasonOptions"
                      aria-describedby="to-feedback"
                      ><template v-slot:first="">
                        <b-form-select-option :value="null" disabled=""
                          >Please select</b-form-select-option
                        >
                      </template></b-form-select
                    >
                    <b-form-invalid-feedback id="to-feedback">{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
              <b-col sm="8">
                <b-form-group label="Internal Note" label-for="description">
                  <ValidationProvider
                    name="description"
                    rules="required"
                    v-slot="validationContext"
                  >
                    <b-form-textarea
                      id="description"
                      name="description"
                      v-model="form.description"
                      rows="2"
                      :state="getValidationState(validationContext)"
                      placeholder="i.e. received HC, not HMC+"
                      aria-describedby="description-feedback"
                    ></b-form-textarea>
                    <b-form-invalid-feedback id="description-feedback">{{
                      validationContext.errors[0]
                    }}</b-form-invalid-feedback>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <b-row class="mb-3">
            <b-col class="d-flex align-items-center" sm="1"><label>Lenses</label></b-col>
            <b-col sm="8">
              <div class="ml-2">
                {{ lensAddonCombination.lenses.name }}
                <b-button class="ml-3" size="sm" @click="handleEditSelectedLenses">Edit</b-button>
              </div>
            </b-col>
          </b-row>
          <b-row class="mb-3">
            <b-col sm="1"><label>Supplier</label></b-col>
            <b-col sm="11">
              <div class="ml-2">{{ orderLensData.supplier.name }}</div>
            </b-col>
          </b-row>
          <b-row class="mb-3">
            <b-col sm="1"><label>Product</label></b-col>
            <b-col sm="11">
              <div class="ml-2">{{ _.get(orderLensData, 'item.region_product.product.name') }}</div>
            </b-col>
          </b-row>
          <b-row class="mb-3">
            <b-col sm="2"
              ><label>Index</label>
              <div>{{ lensAddonCombination.lenses.index }}</div>
            </b-col>
            <b-col sm="2"
              ><label>Type</label>
              <div>{{ _.get(lensAddonCombination, 'lenses.lens_type.name') }}</div>
            </b-col>
            <b-col sm="2"
              ><label>Base Coating</label>
              <div>{{ _.get(lensAddonCombination, 'lenses.coating') }}</div>
            </b-col>
            <b-col sm="2"
              ><label>Treatment</label>
              <div>
                {{ _.get(lensAddonCombination, 'lenses.treatment') }}
              </div>
            </b-col>
            <b-col sm="2"
              ><label>+ Tint</label>
              <div v-if="lensAddonCombination.tint">{{ lensAddonCombination.tint.name }}</div>
            </b-col>
            <b-col sm="2"
              ><label>+ Coating</label>
              <div v-if="lensAddonCombination.coating">{{ lensAddonCombination.coating.name }}</div>
            </b-col>
          </b-row>
          <hr class="my-4" />
          <b-row>
            <b-col sm="3"
              ><label>Prescription</label>
              <div>
                <PrescriptionDetail
                  :prescription="prescriptionDetail"
                  :lensesToPick="lensesToPick"
                ></PrescriptionDetail>
              </div>
            </b-col>
            <b-col sm="3" v-if="additionalInfo.pdPresenter"
              ><label>Width</label>
              <div>
                <PupilDistanceDetail
                  :pd="additionalInfo.pdPresenter"
                  :sourceMessage="sourceMessage"
                  type="width"
                ></PupilDistanceDetail>
              </div>
            </b-col>
            <b-col sm="3" v-if="additionalInfo.pdPresenter && hasPdHeight"
              ><label>Height</label>
              <div>
                <PupilDistanceDetail
                  :pd="additionalInfo.pdPresenter"
                  :sourceMessage="sourceMessage"
                  type="height"
                ></PupilDistanceDetail>
              </div>
            </b-col>
            <b-col sm="3">
              <label>Frame Size</label>
              <div class="mb-1">
                <template v-if="frameSizeId">{{ frameSizeId }}</template>
                <template v-else>&mdash;</template>
              </div>

              <label>Frame Color</label>
              <div class="mb-1">
                <template v-if="frameColor">{{ frameColor }}</template>
                <template v-else>&mdash;</template>
              </div>
            </b-col>
          </b-row>
          <hr class="my-4" />

          <b-row>
            <b-col :cols="checkSuplierForEdge ? '6' : '12'">
              <b-form-group class="flex-fill" label="Type" label-for="type">
                <b-form-radio-group
                  class="custom-radio-button"
                  id="type"
                  v-model="form.lenses"
                  :options="lensesOptions"
                  buttons=""
                  button-variant="white"
                ></b-form-radio-group>
              </b-form-group>
            </b-col>

            <b-col cols="6" v-if="checkSuplierForEdge">
              <b-form-group class="flex-fill" label="Edging" label-for="edge">
                <b-form-select
                  id="edge"
                  v-model="form.need_to_edge"
                  :options="edgeOptions"
                  name="edge"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="From" label-for="sender"
                ><select class="form-control" disabled="">
                  <option>{{ fromLocationText }}</option>
                </select></b-form-group
              >
            </b-col>
            <b-col cols="6">
              <ValidationProvider name="Deliver to" rules="required" v-slot="validationContext">
                <b-form-group label="Deliver to" label-for="to">
                  <b-form-select
                    id="delivery"
                    name="to"
                    v-model="form.to"
                    :state="getValidationState(validationContext)"
                    :options="deliveryOptions"
                    aria-describedby="to-feedback"
                    ><template v-slot:first="">
                      <b-form-select-option :value="null" disabled=""
                        >Please select</b-form-select-option
                      >
                    </template></b-form-select
                  >
                  <b-form-invalid-feedback id="to-feedback">{{
                    validationContext.errors[0]
                  }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
          <b-form-group label="Contact (required)" label-for="contact">
            <ValidationProvider name="Contact" rules="required" v-slot="validationContext">
              <b-form-select
                id="contact"
                name="contact"
                v-model="form.contact"
                :state="getValidationState(validationContext)"
                :options="getContacts"
                value-field="id"
                text-field="mail"
                aria-describedby="contact-feedback"
                ><template v-slot:first="">
                  <b-form-select-option :value="null" disabled=""
                    >Please select</b-form-select-option
                  >
                </template></b-form-select
              >
              <b-form-invalid-feedback id="contact-feedback">{{
                validationContext.errors[0]
              }}</b-form-invalid-feedback>
            </ValidationProvider>
          </b-form-group>
          <p class="warning" v-if="checkWarningMessage">
            {{ checkWarningMessage }}
          </p>
          <b-form-group label="Notes" label-for="notes">
            <ValidationProvider
              name="notes"
              :rules="`${
                form.contact === 99
                  ? orderLensData.count > 0
                    ? 'maxLength:18'
                    : 'maxLength:25'
                  : ''
              }`"
              v-slot="validationContext"
            >
              <b-form-textarea
                id="notes"
                name="notes"
                :state="getValidationState(validationContext)"
                v-model="form.notes"
                rows="3"
                placeholder="Additional requirements or details..."
              ></b-form-textarea>
              <b-form-invalid-feedback id="notes">{{
                validationContext.errors[0]
              }}</b-form-invalid-feedback>
            </ValidationProvider>
          </b-form-group>
          <div class="text-right" v-if="!isOnlyFilePrescription">
            <b-button
              class="button-with-loader"
              type="submit"
              variant="primary"
              :disabled="isSent"
              v-wait:disabled="'Comment Form'"
            >
              <v-wait for="Send Order">
                <template slot="waiting">
                  <div>
                    <b-spinner small=""></b-spinner>
                  </div> </template
                >Send</v-wait
              >
            </b-button>
          </div>
          <div v-else>
            <p class="error">Please edit prescription</p>
          </div>
        </b-form>
      </ValidationObserver>
    </div>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import apiOrders from '@/api/orders';
import PrescriptionDetail from './PrescriptionDetail.vue';
import PupilDistanceDetail from './PupilDistanceDetail.vue';

export default {
  name: 'OrderLensModal',
  components: {
    PrescriptionDetail,
    PupilDistanceDetail,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      isSent: false,
      form: {
        to: null,
        lenses: 'both',
        need_to_edge: false,
        contact: null,
        reason: null,
        description: '',
        notes: null,
      },
      lensesOptions: [
        { text: 'Both', value: 'both' },
        { text: 'Right', value: 'right' },
        { text: 'Left', value: 'left' },
      ],
      supplier: {},
      reasonOptions: [
        '30 Day Warranty Exchange',
        'Customer Order Lost in Post',
        'Dispenser Error (In Store)',
        'Dispenser Error (Service Centre)',
        'Lab Error (Service Centre)',
        'Non Tolerance',
        'Optom Error',
        'Supplier - Received Broken Lenses',
        'Supplier - Got wrong Index',
        'Supplier - Got wrong Diameter',
        'Supplier - Got wrong Color',
        'Supplier - Peel Off or bad Coating',
        'Supplier - Got scratched lenses',
        'Supplier - Got wrong Power',
        'Supplier - Got wrong Prism',
        'Supplier - Got wrong Lenses',
        'Other',
      ],
      contactOptions: [],
    };
  },
  computed: {
    ...mapState({
      orderLensData: state => state.orderLensModal.orderLensData,
      frameSizeId: state => state.orderLensModal.frameSizeId,
      outlets: state => state.outlets,
      order: state => state.orderLensModal.order,
    }),
    ...mapState('auth', {
      user: state => state.user,
      selectedRegion: state => state.selectedRegionId,
      authenticatedOutletId: state => state.selectedOutletId,
    }),
    sourceMessage() {
      const sourceId = this._.get(this.item, 'additional_info.data.pd.detail.source_id');
      if (sourceId && this.$store.state.pdSources) {
        const selectedSource = this.$store.state.pdSources.find(
          source => source.slug === 'o-pm' && source.id === sourceId,
        );
        if (selectedSource) {
          return true;
        }
      }
      return false;
    },
    checkWarningMessage() {
      return this._.get(
        this.orderLensData,
        'item.additional_info.data.lens_addon_combination.lens_warning_message',
      );
    },
    getContacts() {
      const getOrderType = this.orderLensData.supplier.order_type;
      let { contacts } = this.orderLensData.supplier;
      if (
        this.orderLensData.frameSizeId !== 'Non-Dresden' &&
        this.orderLensData.supplier.name === 'Hoya'
      ) {
        if (getOrderType === 'all') {
          if (contacts.filter(contact => contact.id === 99).length === 0) {
            contacts = [
              {
                created_at: '2021-10-14 10:31:56',
                first_name: 'Hoya',
                full_name: 'Hoya Australia',
                last_name: 'Australia',
                id: 99,
                mail: 'EDI',
                phone: null,
                region_id: 7,
                supplier_id: 27,
              },
            ];
          }

          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.form.contact = 99;
          if (this.checkWarningMessage) {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.form.need_to_edge = 'UnCut';
          }
        } else if (getOrderType === 'api') {
          contacts = [
            {
              created_at: '2021-10-14 10:31:56',
              first_name: 'Hoya',
              full_name: 'Hoya Australia',
              last_name: 'Australia',
              mail: 'EDI',
              phone: null,
              id: 99,
              region_id: 7,
              supplier_id: 27,
            },
          ];
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.form.contact = 99;
        }
      }
      if (
        this.orderLensData.frameSizeId !== 'Non-Dresden' &&
        this.orderLensData.supplier.name === 'Shamir'
      ) {
        if (getOrderType === 'all') {
          if (contacts.filter(contact => contact.id === 99).length === 0) {
            contacts = [
              {
                created_at: '2021-10-14 10:31:56',
                first_name: 'SHAMIR',
                full_name: 'SHAMIR',
                last_name: 'SHAMIR',
                id: 999,
                mail: 'Shamir API',
                phone: null,
                region_id: 7,
                supplier_id: 31,
              },
            ];
          }
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          // this.form.need_to_edge = 'Cut';
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.form.contact = 999;
        } else if (getOrderType === 'api') {
          contacts = [
            {
              created_at: '2021-10-14 10:31:56',
              first_name: 'SHAMIR',
              full_name: 'SHAMIR',
              last_name: 'SHAMIR',
              id: 999,
              mail: 'Shamir API',
              phone: null,
              region_id: 7,
              supplier_id: 31,
            },
          ];
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.form.contact = 999;
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          // this.form.need_to_edge = 'Cut';
        }
      }

      if (
        this.orderLensData.frameSizeId !== 'Non-Dresden' &&
        this.orderLensData.supplier.name === 'PlasticPlus'
      ) {
        if (getOrderType === 'all') {
          if (contacts.filter(contact => contact.id === 99).length === 0) {
            contacts = [
              {
                created_at: '2021-10-14 10:31:56',
                first_name: 'PlasticPlus',
                full_name: 'PlasticPlus',
                last_name: 'PlasticPlus',
                id: 999,
                mail: 'PlasticPlus API',
                phone: null,
                region_id: 7,
                supplier_id: 34,
              },
            ];
          }
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          // this.form.need_to_edge = 'Cut';
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.form.contact = 999;
        } else if (getOrderType === 'api') {
          contacts = [
            {
              created_at: '2021-10-14 10:31:56',
              first_name: 'PlasticPlus',
              full_name: 'PlasticPlus',
              last_name: 'PlasticPlus',
              id: 999,
              mail: 'PlasticPlus API',
              phone: null,
              region_id: 7,
              supplier_id: 34,
            },
          ];
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.form.contact = 999;
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          // this.form.need_to_edge = 'Cut';
        }
      }

      if (this.form.need_to_edge === 'FTC') {
        contacts = contacts.filter(contact => contact.id !== 99);
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.form.contact = contacts[0].id;
      }

      const zeisSuplierIds = [2, 3, 4];
      contacts = contacts.filter(contact => !zeisSuplierIds.includes(contact.supplier_id));
      return contacts;
    },
    edgeOptions() {
      const cutOptions = this.orderLensData.supplier.cut_options;
      const options = [];

      if (cutOptions.UnCut.valid) {
        options.push({ text: 'Uncut lenses', value: 'UnCut' });
      }
      if (cutOptions.cut.valid) {
        options.push({
          text: `Cut to trace - ${this.frameSizeId?.toUpperCase?.() || ''}`,
          value: 'Cut',
        });
      }

      // if (this.orderLensData.supplier.name === 'Hoya') {
      //   return [{ text: 'Frame to follow', value: 'FTC' }];
      // }

      if (this.form.contact === 99 || this.form.contact === 999) {
        //if hoya select uncut
        if (this.orderLensData.supplier.name === 'Hoya') {
          this.form.need_to_edge = 'UnCut';
        }
        return options;
      }

      return [
        { text: 'Uncut lenses', value: 'UnCut' },
        {
          text: `Cut to trace - ${this.frameSizeId?.toUpperCase?.() || ''}`,
          value: 'Cut',
        },
        { text: 'Frame to follow', value: 'FTC' },
      ];
    },
    selectedOutletId() {
      const itemOutletId = this.item?.outlet?.id;
      if (itemOutletId) return itemOutletId;
      return this.authenticatedOutletId;
    },
    sender() {
      return `Dresden ${this.user.outlet.name}`;
    },
    frameColor() {
      return this._.get(this.additionalInfoData, 'customize.component.Frame.color.name');
    },
    item() {
      return this.orderLensData.item;
    },
    additionalInfo() {
      return this._.get(this.item, 'additional_info');
    },
    fromLocationText() {
      if (this.orderLensData.supplier.name === 'Shamir') {
        return this.order.origin_outlet.name;
      }
      return `Dresden ${this.item?.outlet?.name}`;
    },
    additionalInfoData() {
      return this._.get(this.additionalInfo, 'data');
    },
    lensAddonCombination() {
      return this._.get(this.additionalInfoData, 'lens_addon_combination');
    },
    checkSuplierForEdge() {
      return (
        this.orderLensData.supplier.abbreviation === 'AU-HY' ||
        this.orderLensData.supplier.abbreviation === 'AU-SH' ||
        this.orderLensData.supplier.abbreviation === 'CA-PP' ||
        this.orderLensData.supplier.abbreviation === 'AU-PP'
      );
    },
    prescriptionDetail() {
      return this._.get(this.additionalInfoData, 'prescription.detail');
    },
    lensesToPick() {
      return this._.get(this.additionalInfo, 'lensesToPick');
    },
    hasPdHeight() {
      return !!this._.get(this.additionalInfo, 'pdPresenter.data.height.right');
    },
    standardDisplay() {
      return this._.get(this.prescriptionDetail, 'standardised_prescription_display_array');
    },
    isOnlyFilePrescription() {
      if (
        this.standardDisplay.left === 'L Plano DS' &&
        this.standardDisplay.right === 'R Plano DS' &&
        this.prescriptionDetail.add_int == null &&
        this.prescriptionDetail.add_near == null &&
        this.prescriptionDetail.left_int_add == null &&
        this.prescriptionDetail.right_int_add == null &&
        this.prescriptionDetail.right_near_add == null &&
        this.prescriptionDetail.left_near_add == null &&
        this.prescriptionDetail.files &&
        this.prescriptionDetail.files.length > 0
      ) {
        return true;
      }
      return false;
    },
    deliveryOptions() {
      if (
        this.orderLensData.supplier.name === 'Shamir' ||
        this.orderLensData.supplier.name === 'Hoya'
      ) {
        return this.outlets
          .filter(
            outlet =>
              (outlet.region_id === this.selectedRegion || outlet.id === this.selectedOutletId) &&
              outlet.is_active === true &&
              outlet.name !== 'Brunswick',
          )
          .map(outlet => {
            return {
              value: outlet.id,
              text: outlet.name,
              id: outlet.id,
            };
          });
      }

      return this.outlets
        .filter(
          outlet =>
            (outlet.region_id === this.selectedRegion || outlet.id === this.selectedOutletId) &&
            outlet.is_active === true &&
            outlet.address,
        )
        .map(outlet => {
          const addressString = this.$options.filters.address(this._.get(outlet, 'address'));
          return {
            value: addressString,
            text: addressString,
            id: outlet.id,
          };
        });
    },
    modalTitle() {
      const invoice = this.orderLensData.invoiceNumber;
      const firstName = this._.get(this.orderLensData, 'customer.first_name');

      if (invoice && firstName) {
        return `${invoice} — ${firstName} ${this.orderLensData.customer.last_name}`;
      }

      if (invoice && !firstName) {
        return `${this.orderLensData.invoiceNumber} — Guest`;
      }
      return invoice;
    },
    currentLocationAddress() {
      const location = this._.find(this.deliveryOptions, { id: this.selectedOutletId });
      return location ? location.value : null;
    },
    isStorePickup() {
      const deliveryOptionId = this._.get(this.item, 'delivery_type_id');
      return deliveryOptionId === 1;
    },
  },
  methods: {
    onShow() {
      this.populateData();
      if (this.orderLensData.frameSizeId !== 'Non-Dresden') {
        this.form.need_to_edge = 'Cut';
      } else {
        this.form.need_to_edge = 'FTC';
      }
      // check shamir order
      if (this.orderLensData.supplier.name === 'Shamir') {
        const originOutletId = this._.get(this.order, 'origin_outlet.id');
        const originOutletName = this._.get(this.order, 'origin_outlet.name');
        if (originOutletId) {
          const mappingOutlet = {
            // eslint-disable-next-line prettier/prettier
            BHF: '25',
            'Ecommerce NZ': '25',
            'Service Centre NZ': '25',
            'Online NZ': '25',
            'Online AU': '25',
            'ONLINE AU DEV': '25',
          };
          if (this.isStorePickup) {
            this.form.to = this.selectedOutletId;
          } else if (originOutletName in mappingOutlet) {
            this.form.to = mappingOutlet[originOutletName];
          } else {
            const checkOutletExists = this.deliveryOptions.filter(
              outlet => outlet.id === originOutletId,
            );
            if (checkOutletExists.length > 0) {
              this.form.to = originOutletId;
            }
          }
        }
      }
    },

    onHide() {
      this.$store.commit('addressModal/HIDE_NEW_ADDRESS_FORM');
    },
    populateData() {
      this.form.lenses = 'both';
      this.form.notes = null;
      const { defaultContact } = this.orderLensData;
      const contact = defaultContact
        ? this.orderLensData.supplier.contacts.find(sc => sc.id === defaultContact.id)
        : null;
      this.form.contact = contact ? contact.id : null;
      this.form.to = this.currentLocationAddress ? this.currentLocationAddress : null;
      this.form.reason = null;
      this.form.description = '';
      this.isSent = false;
    },
    sendOrder() {
      this.isSent = true;
      let contactId = this.form.contact;
      let orderType = null;
      if (this.form.contact === 99) {
        orderType = 'api';
        contactId = 8;
      }
      if (this.form.contact === 999) {
        orderType = 'api';
        if (this.orderLensData.supplier.name === 'PlasticPlus') {
          contactId = 34;
        } else {
          contactId = 31;
        }
      }
      this.$wait.start('Send Order');
      const mailData = {
        type: 'customer',
        outlet_id: this.orderLensData.order.origin_outlet.id,
        user_id: this.user.id,
        order_id: this.item.order_id,
        product_id: this.item.region_product.product_id,
        item: this.item,
        frame_size: this.frameSizeId,
        to: this.form.to,
        lenses: this.form.lenses,
        from: this.fromLocationText,
        supplier_contact_id: contactId,
        order_type: orderType,
        notes: this.form.notes,
        reason: this.form.reason,
        description: this.form.description,
        need_to_edge: this.form.need_to_edge,
      };
      apiOrders
        .orderLens(mailData)
        .then(() => {
          this.$store.dispatch('showSuccessAlert', 'Lens ordered successfully.');
          apiOrders.getOrder(this.item.order_id).then(({ data: { data: job } }) => {
            this.$store.commit('UPDATE_JOB_IN_JOBS', job);
          });
        })
        .catch(({ response }) => {
          this.$store.dispatch('showErrorAlert', this._.get(response, 'data.error', 'Error'));
        })
        .finally(() => {
          this.$wait.end('Send Order');
          this.$bvModal.hide('order-lens-modal');
          this.form.contact = null;
        });
    },
    handleEditSelectedLenses() {
      this.orderLensData.vm.openEditJobModal(4);
      this.$bvModal.hide('order-lens-modal');
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    'form.contact': function (newContact) {
      let oldLensesOptions = [
        { text: 'Both', value: 'both' },
        { text: 'Right', value: 'right' },
        { text: 'Left', value: 'left' },
      ];
      if (newContact === 999) {
        oldLensesOptions = [{ text: 'Both', value: 'both' }];
      }
      this.lensesOptions = oldLensesOptions;
    },
  },
};
</script>
